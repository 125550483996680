import { ApiError } from "@/oas/generated"
import { useNotificationStore } from "@/stores/NotificationStore"

export default class AcErrorHandler {
  static onError(err: any) {
    useNotificationStore().openErrorNotification(err as string)
  }
}

export function getMessageFromErrorResponse(err: any) {
  let errorMsg = err.msg
  if (err.body.detail) {
    errorMsg = err.body.detail
  } else {
    errorMsg = err.statusText
  }
  return errorMsg
}

export function handleNotAllowedFileTypeError(err: any) {
  let result = undefined
  if (err instanceof ApiError) {
    const apiError = err as ApiError
    try {
      result = apiError.body?.errors?.File[0]
    } finally {
      if (!result) {
        result = getMessageFromErrorResponse(apiError)
      }
    }
  } else {
    result = err.message
  }
  return result
}
